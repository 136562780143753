import types from './ActionTypes'
import API from './services'
import { initialState } from './Reducers'
import initializeResp from './dummyjson/initializeresp.json'

import {setAuthToken} from './services'
const middlewareErrorHanlder = (dispatch) => (error) => {
  // dispatch({ type: types.API_ERROR, data: error })
  dispatch({ type: types.UNSET_LOADING_INDICATOR })
}
const middleware = {}


middleware[types.GET_MASTER_DATA_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    // Promise.resolve(initializeResp)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_MASTER_DATA_SUCCESS, data: data.data.Masterdata })
    })
    .catch((error) => {
      dispatch({ type: types.GET_MASTER_DATA_FAILURE, data: error })
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.SEND_OTP_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.SEND_OTP_SUCCESS, data: data.data.UpdateEmailResponse })
    })
    .catch((error) => {
      dispatch({ type: types.SEND_OTP_FAILURE, data: error })
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.VALIDATE_OTP_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    // Promise.resolve(true)
    .then((data) => {
      // data = { data: { "OTPVerificationResult": { "message": "success" } } }
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.VALIDATE_OTP_SUCCESS, data: data.data.OTPVerificationResult })
    })
    .catch((error) => {
      dispatch({ type: types.VALIDATE_OTP_FAILURE, data: error })
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.CREATE_QUOTE_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data.createQuoteRequest)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.CREATE_QUOTE_SUCCESS, data: data.data.CreateQuoteResponse })
      if (data.data.CreateQuoteResponse) {
        dispatch({ type: types.SET_LOADING_INDICATOR })
        action.data.updateCustomerRequest.tradeId = data.data.CreateQuoteResponse.tradeId
        dispatch({ type: types.UPDATE_CUSTOMER_REQUEST, data: action.data.updateCustomerRequest })
      }
    })
    .catch((error) => {
      dispatch({ type: types.CREATE_QUOTE_FAILURE, data: error })
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.UPDATE_CUSTOMER_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.UPDATE_CUSTOMER_SUCCESS, data })
    })
    .catch((error) => {
      dispatch({ type: types.UPDATE_CUSTOMER_FAILURE, data: error })
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.SEND_EMAIL_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.SEND_EMAIL_SUCCESS, data })
    })
    .catch((error) => {
      dispatch({ type: types.SEND_EMAIL_FAILURE, data: error })
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.GET_PRESIGN_URL_FOR_UPLOAD_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_PRESIGN_URL_FOR_UPLOAD_SUCCESS, data: data.data.PresignedURLResponse })
    })
    .catch((error) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({
        type: types.GET_PRESIGN_URL_FOR_UPLOAD_FAILURE, data: error
        // data: { error: error && error.response && error.response.data, id: action.data.id },
      })
    })

middleware[types.GET_DO_GET_ADDRESS] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({
        type: types.GET_DO_GET_ADDRESS_SUCCESS, data: data.data
      })
    })
    .catch((error) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({
        type: types.GET_DO_GET_ADDRESS_FAILURE, data: error
      })
    })


middleware[types.UPDATE_IMEI_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({
        type: types.UPDATE_IMEI_SUCCESS, data: data.data
      })
    })
    .catch((error) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({
        type: types.UPDATE_IMEI_FAILURE, data: error
      })
    })

middleware[types.GET_PRESIGN_URL_2] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_PRESIGN_URL_2_SUCCESS, data: data.data.PresignedURLResponse })
    })
    .catch((error) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({
        type: types.GET_PRESIGN_URL_2_FAILURE, data: error
      })
    })

middleware[types.GET_QR_CODE_PRESIGN_URL] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_QR_CODE_PRESIGN_URL__SUCCESS, data: data.data.PresignedURLResponse })
    })
    .catch((error) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({
        type: types.GET_QR_CODE_PRESIGN_URL_FAILURE, data: error
      })
    })

    middleware[types.GET_PHOTOID_DETAILS_REQUEST] = (dispatch) => (action) =>
      API[action.type](action.data)
        .then((data) => {
          if (data.data.GetPhotoIdDetailsResponse.isDocumentValid) {
            dispatch({ type: types.UNSET_LOADING_INDICATOR })
            dispatch({ type: types.GET_PHOTOID_DETAILS_SUCCESS, data: data.data.GetPhotoIdDetailsResponse })
          } else {
            dispatch({ type: types.UNSET_LOADING_INDICATOR })
            dispatch({
              type: types.GET_PHOTOID_DETAILS_FAILURE, data: error
              // data: { error: error && error.response && error.response.data, id: action.data.id },
            })
          }
        })
        .catch((error) => {
          dispatch({ type: types.UNSET_LOADING_INDICATOR })
          dispatch({
            type: types.GET_PHOTOID_DETAILS_FAILURE, data: error
            // data: { error: error && error.response && error.response.data, id: action.data.id },
          })
        })

    middleware[types.CREATE_INQUIRY_REQUEST] = (dispatch) => (action) =>
    API[action.type](action.data)
      // Promise.resolve('test')
      .then((data) => {
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        dispatch({ type: types.CREATE_INQUIRY_SUCCESS, data: data.data })
      })
      .catch(middlewareErrorHanlder(dispatch))
  
  middleware[types.SET_TRADE_DETAILS] = (dispatch) => (action) =>
    API[action.type](action.data, action.token)
      .then((data) => {
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        dispatch({ type: types.SET_TRADE_DETAILS_SUCCESS, data: data.data })
      })
      .catch(middlewareErrorHanlder(dispatch))
  
  middleware[types.SET_TRADE_STATUS] = (dispatch) => (action) =>
    API[action.type](action.data, action.token)
      .then((data) => {
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        dispatch({ type: types.SET_TRADE_STATUS_SUCCESS, data: data.data })
      })
      .catch(middlewareErrorHanlder(dispatch))
  
  // middleware[types.SEND_OTP_REQUEST] = (dispatch) => (action) =>
  //   API[action.type](action.data)
  //     .then((data) => {
  //       dispatch({ type: types.UNSET_LOADING_INDICATOR })
  //       dispatch({ type: types.SEND_OTP_SUCCESS, data: data.data })
  //     })
  //     // .catch(middlewareErrorHanlder(dispatch))
  //     .catch((error) => {
  //       dispatch({ type: types.SEND_OTP_FAILURE, data: error })
  //       dispatch({ type: types.UNSET_LOADING_INDICATOR })
  //     })
  
  // middleware[types.VERIFY_OTP_REQUEST] = (dispatch) => (action) =>
  //   API[action.type](action.data, action.token)
  //     .then((data) => {
  //       dispatch({ type: types.UNSET_LOADING_INDICATOR })
  //       dispatch({ type: types.VERIFY_OTP_SUCCESS, data: data.data })
  //     })
  //     .catch(middlewareErrorHanlder(dispatch))
  
  middleware[types.SET_GENERATE_LABEL_REQUEST] = (dispatch) => (action) =>
    API[action.type](action.data, action.token)
      .then((data) => {
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        dispatch({ type: types.SET_GENERATE_LABEL, data: data.data })
      })
      .catch(middlewareErrorHanlder(dispatch))
  
  middleware[types.SET_SHIPPING_LABEL_REQUEST] = (dispatch) => (action) =>
    API[action.type](action.data, action.token)
      .then((data) => {
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        dispatch({ type: types.SET_SHIPPING_LABEL_SUCCESS, data: data.data })
      })
      .catch(middlewareErrorHanlder(dispatch))
  
  middleware[types.GET_IMAGE_REQUEST] = (dispatch) => (action) =>
    API[action.type](action.data, action.token)
      .then((data) => {
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        dispatch({ type: types.GET_IMAGE_SUCCESS, data: data.data })
      })
      .catch(middlewareErrorHanlder(dispatch))
  
  middleware[types.CREATE_HYPER_WALLET_USER] = (dispatch) => (action) =>
      API[action.type](action.data, action.token)
        .then((data) => {
          dispatch({ type: types.UNSET_LOADING_INDICATOR })  
        })
        .catch(middlewareErrorHanlder(dispatch))
  
  middleware[types.CREATE_TRANSFER_METHOD] = (dispatch) => (action) =>
      API[action.type](action.data, action.token)
        .then((resp) => {
            dispatch({ type: types.SAVE_PAYOUT_DETAILS_SUCCESS})   
            const payoutRequest = {
              createHyperwalletPayoutRequest: {
                tradeId:  action.data.createHyperwalletTransferMethodRequest.tradeId
              },
            }
            dispatch({ type: types.CREATE_PAYOUT, data: payoutRequest, token: action.token })
        })
        .catch((error) => {
            dispatch({ type: types.UNSET_LOADING_INDICATOR })
            dispatch({ type: types.SUBMIT_PAYOUT_FAILURE })
        })
      
  middleware[types.CREATE_PAYOUT] = (dispatch) => (action) =>
      API[action.type](action.data, action.token)
        .then((resp) => {
            dispatch({ type: types.SUBMIT_PAYOUT_SUCCESS })
            dispatch({ type: types.UNSET_LOADING_INDICATOR })
        })
        .catch((error) => {
            dispatch({ type: types.UNSET_LOADING_INDICATOR })
            dispatch({ type: types.SUBMIT_PAYOUT_FAILURE })
        })
  
  middleware[types.SEND_SS_OTP_REQUEST] = (dispatch) => (action) =>
    API[action.type](action.data)
      .then((data) => {
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        const token  =  data.data.OTPResponse.TradeAccess;
        localStorage.setItem("token", token);
      //  if(sessionToken) {
      //   setAuthToken(sessionToken);
      //  }
        dispatch({ type: types.SEND_SS_OTP_REQUEST_SUCCESS, data: data.data.OTPResponse })
      })
      .catch((error) => {
        dispatch({ type: types.SEND_SS_OTP_REQUEST_FAILURE, data: error })
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
      })

  middleware[types.VERIFY_SS_OTP_REQUEST] = (dispatch) => (action) =>
    API[action.type](action.data)
      // Promise.resolve(true)
      .then((data) => {
        // data = { data: { "OTPVerificationResult": { "message": "success" } } }
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        dispatch({ type: types.VERIFY_SS_OTP_REQUEST_SUCCESS, data: data.data.EmailVerificationResult })
      })
      .catch((error) => {
        dispatch({ type: types.VERIFY_SS_OTP_REQUEST_FAILURE, data: error })
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
      })

  middleware[types.Get_ALL_SS_TRADES] = (dispatch) => (action) =>
    API[action.type](action.data)
      // Promise.resolve(true)
      .then((data) => {
        // data = { data: { "OTPVerificationResult": { "message": "success" } } }
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        dispatch({ type: types.Get_ALL_SS_TRADES_SUCCESS, data: data })
      })
      .catch((error) => {
        dispatch({ type: types.Get_ALL_SS_TRADES_FAILURE, data: data })
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
      })

  middleware[types.GET_SS_TRADE_DETAILS] = (dispatch) => (action) =>
      API[action.type](action.data?.payload)
        .then((data) => {
          dispatch({ type: types.UNSET_LOADING_INDICATOR })
          dispatch({ type: types.GET_SS_TRADE_DETAILS_SUCCESS, data: data.data.TradeDetails })
          if(action.data?.fromOtp)
          {
            dispatch({ type: types.SET_SSP_PAGE, data:"TradeDetails"})
          }
        })
        .catch((error) => {
          dispatch({ type: types.GET_SS_TRADE_DETAILS_FAILURE, data: data })
          dispatch({ type: types.UNSET_LOADING_INDICATOR })
        })

  middleware[types.CANCEL_SS_TRADE_DETAILS] = (dispatch) => (action) =>
    API[action.type](action.data?.cancelpayload)
      .then((data) => {
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        dispatch({ type: types.CANCEL_SS_TRADE_DETAILS_SUCCESS, data: data.data.CancelQuoteResponse })
        if(action.data?.gettradepayload)
        {
          dispatch({ type:types.GET_SS_TRADE_DETAILS, data:action.data?.gettradepayload})
        }

      })
      .catch((error) => {
        dispatch({ type: types.CANCEL_SS_TRADE_DETAILS_FAILURE, data: data })
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
      })
  
  middleware[types.RETURN_SS_LABEL] = (dispatch) => (action) =>
    API[action.type](action.data)
      .then((data) => {
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        dispatch({ type: types.SET_GENERATE_LABEL, data: data.data })
      })
      .catch((error) => {
        dispatch({ type: types.RETURN_SS_LABEL_FAILURE, data: data })
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
      })
  

const applyMiddleware = (dispatch, state) => (action) =>
  middleware[action.type] && middleware[action.type](dispatch, state)(action)

export { applyMiddleware }
